import { render, staticRenderFns } from "./LeaguesMandatory.vue?vue&type=template&id=04aa0d00&scoped=true&"
import script from "./LeaguesMandatory.vue?vue&type=script&lang=js&"
export * from "./LeaguesMandatory.vue?vue&type=script&lang=js&"
import style0 from "./LeaguesMandatory.vue?vue&type=style&index=0&id=04aa0d00&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04aa0d00",
  null
  
)

export default component.exports